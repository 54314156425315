<template>
  <b-card v-if="data">
    <b-row >

      <b-col

      >
        <vue-apex-charts type="area" :options="chartOptions" :series="series"           height="300"
        />

        <!-- apex chart -->

      </b-col>
    </b-row>

    <hr>

    <!-- progress bar -->
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    dateInfo: {
    },
    conversions: {
    },
  },
  data() {
    return {
    }
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          id: 'area-chart',
          foreColor: store.state.appConfig.layout.skin === 'dark' ? 'white' : 'black', // Initial label color

        },

        xaxis: {
          categories: this.dateInfo.slice(),

          type: 'datetime', // Set the X-axis type to datetime
          labels: {
            formatter: function (val) {
              // Format the date as "d/mm/yyyy"
              const date = new Date(val);
              const day = date.getDate();
              const month = date.getMonth() + 1; // Months are zero-based
              const year = date.getFullYear();
              return `${day}/${month}/${year}`;
            },
          },


        },
        title: {
          text: this.$t('labels.conversions'),
          align: 'center',
          style: {
            fontSize: "16px",
            color: '#666'
          }
        },
        fill: {
          type: 'gradient', // 'solid' can also be used for a solid color
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.3,
            stops: [0, 90, 100],
          },
        },
      };
    },

    series() {
      return [
        {
          name: this.$t('labels.conversions'),
          data:this.conversions.slice(),
          color: $themeColors.primary, // Specify color for Series 1

        },



      ];
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
