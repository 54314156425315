<template>
  <div class="card" >
        <b-card-header class="align-items-baseline">
    <canvas id="myChart"   width="400" height="200"></canvas>
    </b-card-header>

  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    countries: {
      type:Array
    },
    users: {
      type:Array
    },
  },
  data() {
    return {
      dataSet:[],




    }
  },


  mounted() {
    this.renderChart(this.chartData, { responsive: true, maintainAspectRatio: false });

  },
  methods: {
    renderChart() {
      const ctx = document.getElementById("myChart").getContext("2d");

      // console.log(this.countries)

      this.pieChart =  new Chart(ctx, {
        type: "pie",
        data: {

          labels: this.countries.slice() ,
          datasets: [
            {
              label: "Top five Countries",
              data: this.users.slice(),
              backgroundColor: ['#9958c2', '#b675e7', '#76449e','#904abc','#ac4def'],
              hoverBackgroundColor: ['#9958c2', '#b675e7', '#76449e','#904abc','#ac4def'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: this.$t('labels.active_users_by_country')
          },
          scales: {
            y: {
              beginAtZero: true,
            },

          },
        },

      });
    },

    refreshPieChart() {
      // Destroy the existing chart
      this.pieChart.destroy();
      // Re-render the chart
      this.renderChart();
    },

  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
