<template>

  <section id="dashboard-analytics">
    <div>
      <div class="card" >
        <b-card-header class="align-items-baseline">
          <div>
            <b-dropdown
              :text="selectedPeriod ? types.find(t => t.value === selectedPeriod).name : 'Select Period'"
              class="chart-dropdown"
              left
              no-caret
              size="sm"
              v-model="selectedPeriod"
            >
              <b-dropdown-item
                v-for="type in types"
                :key="type.value"
                :value="type.value"
                @click="handlePeriodChange(type.value)"
              >
                {{ type.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-card-header>

      </div>

    </div>
    <div v-if='isDataLoaded'>
      <div class='text-center text-primary my-2'>
        <b-spinner class='align-middle' />
      </div>
    </div>
    <div v-else>
      <b-row class="match-height">
        <b-col
          lg="6"
        >
          <analytics-new-users   :new-users="userCate" :date-cate="dateCate"/>
        </b-col>
        <b-col
          lg="6"
        >
          <platform-chart :infos="operatingSystem" :users="dataSetPlatform" ref="platformChart" />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col lg="6">
          <CountriesChart :countries="countries" :users="dataSet" ref="countriesChart" />
        </b-col>
        <b-col lg="6">
          <analytics-support-tracker :data="data.supportTracker" :total-users="totalUsers" :new-users="newUsers" :active-users="activeUsers" />


        </b-col>

      </b-row>

      <b-row class="match-height" >
        <b-col lg="8">
          <analytics-conversions :data="data.avgSessions"  :conversions="conversions" :date-info="conversionDate"/>
        </b-col>
        <b-col lg="4">
          <analytics-app-design :customers="customers" :deleted-accounts="deletedAccounts" :guest="guest"  />
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsConversions from './AnalyticsConversions.vue'
import AnalyticsNewUsers from './AnalyticsNewUsers.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import CountriesChart from './CountriesChart.vue';
import PlatformChart from './PlatformChart.vue'
import AnalyticsPlatformTypes from './AnalyticsPlatformTypes.vue'


export default {
  components: {
    PlatformChart,
    BRow,
    BCol,
    CountriesChart,
    AnalyticsCongratulation,
    AnalyticsConversions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    AnalyticsNewUsers,
    AnalyticsPlatformTypes

  },
  data() {
    return {
      loading: false,
      isDataLoaded:false,
      dataSet:[],
      operatingSystem:[],
      dataSetPlatform:[],
      countries:[],
      newUsers:0,
      activeUsers:0,
      totalUsers:0,
      conversions:[],
      conversionDate:[],
      dateCate:[],
      userCate:[],
      date:[],
      customers:0,
      guest:0,
      deletedAccounts: 0,
      types: [
        { name: 'Last 7 Days', value: 'last_7_days' },
        { name: 'Last 14 Days', value: 'last_14_days'},
        { name: 'Last 30 Days', value: 'last_30_days' },
        { name: 'Last 2 Months', value: 'last_2_months' },
        { name: 'Last 3 Months', value: 'last_3_months' },
        { name: 'Last 6 Months', value: 'last_6_months' },
        { name: 'Last 1 Year', value: 'last_1_year' },
      ],
      selectedPeriod: null,
      period: null,
    }
  },


created() {
  this.isDataLoaded = true;
  this.fetchData();
},

  mounted() {
    // data

    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
      .finally(() => {
        this.loading = false;
      });


  },


  methods: {
    handlePeriodChange(value) {
      this.isDataLoaded = true
      this.selectedPeriod = value;
      this.period = value;
      this.fetchData();
    },
    fetchData() {
      this.axios.get(`/googleAnalytics?period=${this.selectedPeriod}`)
        .then(res => {
          this.customers = res.data.customers
          this.guest = res.data.guest
          this.deletedAccounts = res.data.deletedAccounts


          this.countries = res.data.usersByCountry.map(item => item.country)
          this.dataSet = res.data.usersByCountry.map(item => item.activeUsers)

          this.operatingSystem = res.data.activeUsersByPlatform.map(item => item.operatingSystem)
          this.dataSetPlatform = res.data.activeUsersByPlatform.map(item => item.activeUsers)


          this.activeUsers = res.data.users.map(item => item.activeUsers)
          this.totalUsers = res.data.users.map(item => item.totalUsers)

          this.newUsers = res.data.users.map(item => item.newUsers)
          this.conversions = res.data.conversions.map(item => item.conversions)

          this.conversionDate = res.data.conversions.map(item => item.date)
          this.userCate = res.data.newUsersByDate.map(item => item.newUsers)

          this.date = res.data.newUsersByDate.map(item => item.date)

          this.date.map(dateString => {
            const date = new Date(dateString);
            this.dateCate.push( date.toLocaleDateString('en-GB')); // Adjust the locale as needed
          });
          this.isDataLoaded = false


        })
        .catch(error => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          this.$refs.countriesChart.refreshPieChart();
          this.$refs.platformChart.refreshPlatformChart();


        });
    },
    kFormatter,

  },
}
</script>
